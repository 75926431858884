import React, { useState } from 'react';
import { usePopup } from './PopupContext';
import '../styles/DesignerHeader.css'; // Используем отдельный файл стилей

const DesignerHeader = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
    if (!isMenuVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  const handleMenuClick = (e, target) => {
    e.preventDefault();
    const section = document.querySelector(target);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setMenuVisible(false);
    document.body.style.overflow = '';
  };

  return (
    <header className="designer-headerd">
      <a href="/designer" className="logo hed">
        <img src="/image/logd.svg" alt="Designer Logo" />
      </a>

      <nav className={`navbard ${isMenuVisible ? 'visible' : ''}`}>
        <a href="/#skills-section" data-target="skills-section" onClick={(e) => handleMenuClick(e, '#skills-section')}>
          Скилы
        </a>
        <a href="/#price" data-target="price" onClick={(e) => handleMenuClick(e, '#price')}>
          Прайс
        </a>
        <a href="/#portfolio" data-target="portfolio" onClick={(e) => handleMenuClick(e, '#portfolio')}>
          Портфолио
        </a>
        <a href="/" data-target="web">
          Веб-разработчик
        </a>
        <button className="btn request-btn2" data-target="order" onClick={(e) => handleMenuClick(e, '#order')}>
          Написать дизайнеру
          {/* <img src="/image/hat.png" alt="Новогодняя шапка" className="christmas-hat" /> */}
        </button>
      </nav>

      <div className={`Burgerd ${isMenuVisible ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
};

export default DesignerHeader;
